import MapElementProps from "../MapElementProps";

import './MapStyleControl.scss';
import {useAppSelector} from "../../../redux/hooks";
import {selectLocationAccessAllowed} from "../../../redux/features/location/slice";
import MapIControl from "./MapIControl";

const AllowLocationAccessControl = ({map}: MapElementProps) => {
    const locationAccessAllowed: boolean | undefined = useAppSelector(selectLocationAccessAllowed);
    return (
        <MapIControl map={map!} position='top-left' doShow={() => locationAccessAllowed === false}>
            <div className="error-control">
                Please allow access to location
            </div>
        </MapIControl>
    );
}

export default AllowLocationAccessControl;