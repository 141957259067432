import { useEffect, useRef } from 'react';

// https://overreacted.io/making-setinterval-declarative-with-react-hooks/
function useInterval(callback: () => void, intervalMilliseconds: number) {
    const savedCallback = useRef<() => void>();

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            if (savedCallback?.current) {
                savedCallback.current();
            }
        }
        let id = setInterval(tick, intervalMilliseconds);
        return () => clearInterval(id);
    }, [intervalMilliseconds]);
}

export default useInterval;