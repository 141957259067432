import MapElementProps from "../MapElementProps";

import './MapStyleControl.scss';
import {useAppSelector} from "../../../redux/hooks";
import {LocationInfo, selectUserLocation} from "../../../redux/features/location/slice";
import MapIControl from "./MapIControl";

const WaitingForUserLocationControl = ({map}: MapElementProps) => {
    const userLocation: LocationInfo | undefined = useAppSelector(selectUserLocation);

    return (
        <MapIControl map={map!} position='top-left' doShow={() => !userLocation}>
            <div className="warning-control">
                Waiting for user location
            </div>
        </MapIControl>
    );
}

export default WaitingForUserLocationControl;