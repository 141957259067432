import {
    collection,
    doc,
    Firestore, getFirestore,
    query,
    writeBatch,
    DocumentSnapshot,
    orderBy,
    startAt,
    endAt,
    onSnapshot,
    Unsubscribe
} from "firebase/firestore";
import {geohashForLocation, geohashQueryBounds,} from 'geofire-common';
import firebaseBackend from "../../../../backend/firebase";
import {setTiles, Tile} from "./slice";
import {LocationCoordinate} from "../../location/slice";
import {store} from "../../../store";

export default class TilesProvider {
    private static _instance: TilesProvider;
    public static get instance(): TilesProvider {
        if (!TilesProvider._instance) {
            TilesProvider._instance = new TilesProvider();
        }

        return TilesProvider._instance;
    }

    private constructor() {
    }

    _firestore?: Firestore;
    get firestore(): Firestore {
        if (!this._firestore) {
            this._firestore = getFirestore(firebaseBackend);
        }
        return this._firestore;
    }

    static collectionName = 'tiles';

    private getTileFromDocSnapshot(doc: DocumentSnapshot) {
        const docData = doc.data()!;
        const ret: Tile = {
            bounds: docData.bounds,
            color: docData.color,
            coordinate: docData.coordinate
        };
        return ret;
    }

    async paintTiles(tiles: Tile[]) {
        console.log('paintTiles');
        
        const batch = writeBatch(this.firestore);
        for (const tile of tiles) {
            const hash = geohashForLocation([tile.coordinate.latitude, tile.coordinate.longitude]);

            const docRef = doc(this.firestore, TilesProvider.collectionName, `${tile.coordinate.longitude}:${tile.coordinate.latitude}`);
            batch.set(docRef, {
                ...tile,
                geohash: hash,
            }, {
                merge: true
            });
        }
        await batch.commit();
    }

    prevTilesSubscriptions: Unsubscribe[] = [];

    async getTiles(center: LocationCoordinate, radiusInMeters: number) {
        this.disconnect();

        console.log('getTiles');
        const queryBounds = geohashQueryBounds([center.latitude, center.longitude], radiusInMeters);
        for (const b of queryBounds) {
            const q = query(
                collection(this.firestore, TilesProvider.collectionName),
                orderBy('geohash'),
                startAt(b[0]),
                endAt(b[1]),
            );

            this.prevTilesSubscriptions.push(
                onSnapshot(q, (snapshot) => {
                    const changedTiles: Tile[] = [];
                    snapshot.docChanges().forEach((change) => {
                        const tile = this.getTileFromDocSnapshot(change.doc);
                        switch (change.type) {
                            case "added":
                            case "modified":
                                changedTiles.push(tile);
                                break;
                            case "removed":
                                break;
                        }
                    });
                    store.dispatch(setTiles(changedTiles));
                }));
        }
    }

    disconnect () {
        for (const prevTilesSubscription of this.prevTilesSubscriptions) {
            prevTilesSubscription();
        }
        this.prevTilesSubscriptions = [];
    }
}