import './ColorPicker.scss'
import {SliderPicker} from 'react-color';
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {selectColor, selectDoPaint, setColor} from "../../../redux/features/color/slice";
import MapIControl from './MapIControl';
import MapElementProps from "../MapElementProps";
import {LocationInfo, selectUserLocation} from "../../../redux/features/location/slice";
import {paintCoordinates} from "../../../redux/features/firestore/tiles/slice";

const ColorPicker = ({map}: MapElementProps) => {
    const userLocation: LocationInfo | undefined = useAppSelector(selectUserLocation);
    const doPaint: boolean | undefined = useAppSelector(selectDoPaint);
    const color = useAppSelector(selectColor);
    const dispatch = useAppDispatch();

    if (!color) {
        return <></>;
    }

    return (
            <MapIControl map={map!} position='bottom-left' doShow={() => !!color}>
                <div className='color-picker-container'>
                    <SliderPicker
                            color={{
                                r: color.r,
                                g: color.g,
                                b: color.b
                            }}
                            onChangeComplete={(c) => {
                                dispatch(setColor({
                                    r: c.rgb.r,
                                    g: c.rgb.g,
                                    b: c.rgb.b
                                }));
                                if(userLocation && userLocation.coordinate) {
                                    if (doPaint) {
                                        dispatch(paintCoordinates({
                                            coordinates: [userLocation.coordinate],
                                            color: {
                                                r: c.rgb.r,
                                                g: c.rgb.g,
                                                b: c.rgb.b
                                            },
                                        }));
                                    }
                                }
                            }}/>
                </div>
            </MapIControl>
    );
}

export default ColorPicker;