import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {createBlacklistFilter, createWhitelistFilter} from 'redux-persist-transform-filter';
import {RootState} from "../../../store";
import {Color} from "../../color/color";
import {LocationCoordinate} from "../../location/slice";
import {coordinateToBounds, roundCoordinate} from "../../map/tools";
import {MapBounds} from "../../map/slice";
import TilesProvider from "./TilesProvider";

export interface TilesState {
    tiles: { [lng: number]: { [lat: number]: Tile } };
}

export interface Tile {
    bounds: MapBounds,
    coordinate: LocationCoordinate,
    color: Color,
}

export const selectTiles = (state: RootState) => state.tiles.tiles;

const initialState: TilesState = {
    tiles: {}
};

export const sliceName = 'tiles';
export const persistFilter = createBlacklistFilter(
    sliceName,
    []
);

export interface PaintCoordinates {
    color: Color,
    coordinates: LocationCoordinate[];
}

export interface LocationCoordinateAndRadius {
    coordinate: LocationCoordinate,
    radiusInMeters: number,
}

export const tilesSlice = createSlice({
    name: sliceName,
    initialState: initialState,
    reducers: {
        paintCoordinates(state, action: PayloadAction<PaintCoordinates>) {
            const currentTiles: { [lng: number]: { [lat: number]: Tile } } = {...state.tiles};

            const roundedCoordinates: { [lng: number]: { [lat: number]: Tile } } = {};
            const addedTiles: Tile[] = []
            for (const coordinate of action.payload.coordinates) {
                const roundedCoordinate = roundCoordinate(coordinate);
                if (roundedCoordinate.longitude in currentTiles) {
                    if (currentTiles[roundedCoordinate.longitude][roundedCoordinate.latitude] !== undefined &&
                        currentTiles[roundedCoordinate.longitude][roundedCoordinate.latitude].color.r === action.payload.color.r &&
                        currentTiles[roundedCoordinate.longitude][roundedCoordinate.latitude].color.g === action.payload.color.g &&
                        currentTiles[roundedCoordinate.longitude][roundedCoordinate.latitude].color.b === action.payload.color.b) {
                        continue;
                    }
                }

                if (!(roundedCoordinate.longitude in roundedCoordinates)) {
                    roundedCoordinates[roundedCoordinate.longitude] = {};
                }

                if (!(roundedCoordinate.latitude in roundedCoordinates[roundedCoordinate.longitude])) {
                    const bounds = coordinateToBounds(roundedCoordinate);
                    const newTile = {
                        bounds: bounds,
                        coordinate: roundedCoordinate,
                        color: action.payload.color
                    };
                    roundedCoordinates[roundedCoordinate.longitude][roundedCoordinate.latitude] = newTile;
                    addedTiles.push(newTile);
                }
            }

            for (const tile of addedTiles) {
                if (!(tile.coordinate.longitude in currentTiles)) {
                    currentTiles[tile.coordinate.longitude] = {};
                }
                currentTiles[tile.coordinate.longitude][tile.coordinate.latitude] = tile;
            }
            if (addedTiles.length > 0) {
                state.tiles = currentTiles;
                TilesProvider.instance.paintTiles(addedTiles).catch(console.error);
            }
        },
        getTiles(state, action: PayloadAction<LocationCoordinateAndRadius>) {
            TilesProvider.instance.getTiles(action.payload.coordinate, action.payload.radiusInMeters);
        },
        setTiles(state, action: PayloadAction<Tile[]>) {
            const nextTiles: { [lng: number]: { [lat: number]: Tile } } = {...state.tiles};
            for (const tile of action.payload) {
                if (!(tile.coordinate.longitude in nextTiles)) {
                    nextTiles[tile.coordinate.longitude] = {};
                }
                nextTiles[tile.coordinate.longitude][tile.coordinate.latitude] = tile;
            }
            state.tiles = nextTiles;
        },
        disconnect() {
            TilesProvider.instance.disconnect();
        }
    }
});

export const {paintCoordinates, getTiles, setTiles, disconnect} = tilesSlice.actions;

export default tilesSlice.reducer;
