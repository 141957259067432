import MapElementProps from "../MapElementProps";
import GeoJsonLayer from "./GeoJsonLayer";
import {useEffect, useState} from "react";
import {
    boundsContainsBounds,
    coordStep,
    inflateBounds,
    roundBoundsCoordinates,
} from "../../../redux/features/map/tools";
import {useAppSelector} from "../../../redux/hooks";
import {MapBounds, MapState, selectMapState} from "../../../redux/features/map/slice";

const min_zoom_to_show = 18;
var lastBounds: MapBounds | null = null;

const GridLayer = ({map}: MapElementProps) => {

    const [coordinates, setCoordinates] = useState<GeoJSON.Position[][]>([]);
    const mapState: MapState = useAppSelector(selectMapState);

    const refreshCoordinates = () => {
        if (mapState.locationAndZoom.zoom < min_zoom_to_show) {
            lastBounds = null;
            if (coordinates.length !== 0) {
                setCoordinates([]);
            }
            return;
        }

        const bounds = mapState.bounds;

        if (lastBounds && boundsContainsBounds(lastBounds, bounds)) {
            return;
        }

        const shiftedBounds = inflateBounds(bounds, 10000);
        const roundedBounds = roundBoundsCoordinates(shiftedBounds);

        const currentCoordinates: GeoJSON.Position[][] = [];
        for (let i = Math.max(-89, roundedBounds.south - coordStep / 2); 
             i < Math.min(89, roundedBounds.north + coordStep / 2); 
             i += coordStep) {
            currentCoordinates.push([
                [roundedBounds.west, i],
                [roundedBounds.east, i]
            ])
        }

        for (let i = roundedBounds.west - coordStep / 2; 
             i < roundedBounds.east + coordStep / 2; 
             i += coordStep) {
            currentCoordinates.push([
                [i, roundedBounds.south],
                [i, roundedBounds.north]
            ])
        }

        lastBounds = roundedBounds;
        setCoordinates(currentCoordinates);
    }

    useEffect(() => {
        refreshCoordinates();
    }, [mapState]);

    const [data, setData] = useState<GeoJSON.Feature<GeoJSON.Geometry>>({
        type: "Feature",
        geometry: {
            type: 'MultiLineString',
            coordinates: []
        },
        properties: {},
    }); 
    useEffect(() => {
        setData({
            type: "Feature",
            geometry: {
                type: 'MultiLineString',
                coordinates: coordinates
            },
            properties: {},
        })
    }, [coordinates])
    
    return (<GeoJsonLayer map={map} data={data} layer={{
        id: "GridLayer",
        type: "line",
        layout: {
            "line-join": "round",
            "line-cap": "round",
        },
        paint: {
            "line-color": '#777',
            "line-width": .5,
        },
    }}/>);
}

export default GridLayer;