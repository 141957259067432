import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {createBlacklistFilter} from 'redux-persist-transform-filter';
import {RootState} from "../../store";
import {Color} from "./color";

export interface ColorState {
    color: Color,
    paint: boolean,
}

const sliceName = 'color';

export const persistFilter = createBlacklistFilter(
    sliceName,
    []
);

const initialState: ColorState = {
    color: {r: 255, g: 0, b: 255},
    paint: true,
};

export const selectColor = (state: RootState) => state.color.color;
export const selectDoPaint = (state: RootState) => state.color.paint;

export const colorSlice = createSlice({
    name: sliceName,
    initialState: initialState,
    reducers: {
        setColor(state, action: PayloadAction<Color>) {
            state.color = action.payload;
            state.paint = true;
        },
        doNotPaint(state) {
            state.paint = !state.paint;
        }
    },
});

export const {
    setColor,
    doNotPaint
} = colorSlice.actions;

export default colorSlice.reducer;
