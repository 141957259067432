import {useEffect, useState} from "react";
import mapboxgl, {IControl} from "mapbox-gl";
import {v4 as uuidv4} from 'uuid';

import './MapStyleControl.scss';

export interface MapIControlProps {
    map: mapboxgl.Map,
    children: JSX.Element | JSX.Element[],
    position: 'top-right' | 'top-left' | 'bottom-right' | 'bottom-left',
    doShow: () => boolean,
}

const MapIControl = ({map, children, position, doShow}: MapIControlProps) => {
    const myId = uuidv4();
    const [control, setControl] = useState<IControl>();

    const removeControl = () => {
        if (map && control) {
            map.removeControl(control);
            setControl(control);
        }
    }

    useEffect(() => {
        if (!map)
            return;

        if (doShow()) {
            const c: IControl = {
                onAdd(): HTMLElement {
                    return document.getElementById(myId)!;
                }, onRemove(): void {}
            };
            map.addControl(c, position);
            setControl(c);
        } else {
            removeControl();
        }
    }, [map, doShow()]);

    if (!control) {
        return <div id={myId}></div>;
    }

    return (
        <div id={myId} className="mapboxgl-ctrl mapboxgl-ctrl-group">
            {children}
        </div>
    );
}

export default MapIControl;
