import MapElementProps from "../MapElementProps";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {disconnect, getTiles, selectTiles, Tile} from "../../../redux/features/firestore/tiles/slice";
import {colorToHex} from "../../../redux/features/color/color";
import {Position} from "geojson";
import GeoJsonLayer from "./GeoJsonLayer";
import {useEffect, useRef, useState} from "react";
import {MapBounds, MapState, selectMapState} from "../../../redux/features/map/slice";
import * as turf from '@turf/turf';
import {boundsContainsBounds, inflateBounds} from "../../../redux/features/map/tools";
import useInterval from "../../../helpers/useInterval";

const inflateBoundsMeters = 10000;

const TilesLayer = ({map}: MapElementProps) => {
    const tiles: { [lng: number]: { [lat: number]: Tile } } = useAppSelector(selectTiles);
    const dispatch = useAppDispatch();
    const mapState: MapState = useAppSelector(selectMapState);

    const [dirty, setDirty] = useState(true);
    const lastBounds = useRef<MapBounds>();
    useInterval(() => {
        if (dirty) {
            setDirty(false);
            lastBounds.current = inflateBounds(mapState.bounds, inflateBoundsMeters);
            let distance = turf.distance(
                    [mapState.bounds.west, mapState.bounds.south],
                    [mapState.bounds.east, mapState.bounds.north],
                    {
                        units: 'meters'
                    });
            dispatch(getTiles({
                coordinate: mapState.locationAndZoom.location,
                radiusInMeters: distance + inflateBoundsMeters
            }));
        }
    }, 10000);

    const get_data = () => {
        const data: GeoJSON.FeatureCollection<GeoJSON.Geometry> = {
            type: "FeatureCollection",
            features: [],
        };

        const tilesPerColor: { [key: string]: Tile[] } = {};
        for (const tileLng in tiles) {
            for (const tileLat in tiles[tileLng]) {
                const tile: Tile = tiles[tileLng][tileLat];
                const hexColor = colorToHex(tile.color);
                if (!(hexColor in tilesPerColor)) {
                    tilesPerColor[hexColor] = []
                }
                tilesPerColor[hexColor].push(tile);
            }
        }

        //const offset = .000001;
        for (const color in tilesPerColor) {
            const coordinates: Position[][][] = [];
            for (const tile of tilesPerColor[color]) {
                const tileBounds = tile.bounds;
                coordinates.push([[
                    [tileBounds.east, tileBounds.north],
                    [tileBounds.east, tileBounds.south],
                    [tileBounds.west, tileBounds.south],
                    [tileBounds.west, tileBounds.north],
                    [tileBounds.east, tileBounds.north],
                ]])
                // coordinates.push([[
                //     [tile.coordinate.longitude + offset, tile.coordinate.latitude + offset],
                //     [tile.coordinate.longitude + offset, tile.coordinate.latitude - offset],
                //     [tile.coordinate.longitude - offset, tile.coordinate.latitude - offset],
                //     [tile.coordinate.longitude - offset, tile.coordinate.latitude + offset],
                //     [tile.coordinate.longitude + offset, tile.coordinate.latitude + offset],
                // ]])
            }
            data.features.push({
                type: 'Feature',
                geometry: {
                    type: 'MultiPolygon',
                    coordinates: coordinates
                },
                properties: {
                    "stroke": color,
                    "fill": color,
                    "stroke-width": 0.1,
                },
            });
        }

        return data;
    };

    useEffect(() => {
        if (lastBounds.current) {
            if (!boundsContainsBounds(lastBounds.current, mapState.bounds)) {
                setDirty(true);
            }
        }
    }, [mapState]);
    useEffect(() => {
        return () => {
            dispatch(disconnect())
        };
    }, []);

    return (<GeoJsonLayer map={map} data={get_data()} layer={{
        id: "TilesLayer",
        type: 'fill',
        layout: {},
        paint: {
            'fill-color': ['get', 'fill'],
            'fill-opacity': .7,
        }
    }}/>);
}

export default TilesLayer;