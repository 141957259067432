import {
    locationAccessNotAllowed, locationErrorHappened,
    LocationInfo,
    setLocation
} from "./slice";
import {useAppDispatch} from "../../hooks";
import {useEffect} from "react";

const WatchUserLocation = () => {
    const dispatch = useAppDispatch();
    useEffect(() => {
        const watchId = navigator.geolocation.watchPosition(function (position) {
            const userLocation: LocationInfo = {
                coordinate: {
                    longitude: position.coords.longitude,
                    latitude: position.coords.latitude,
                },
                accuracy: position.coords.accuracy,
                heading: position.coords.heading,
                speed: position.coords.speed,
                unixTimestamp: position.timestamp,
            };
            dispatch(setLocation(userLocation));
        }, function (error) {
            if (error.code === 1) {
                dispatch(locationAccessNotAllowed());
            } else {
                dispatch(locationErrorHappened(`[${error.code}]: ${error.message}`));
            }
        }, {
            maximumAge: 0,
            enableHighAccuracy: true,
        })

        return () => {
            navigator.geolocation.clearWatch(watchId);
        }
    }, [dispatch]);

    // const mapLocationCoordinate: LocationCoordinate = useAppSelector(selectMapLocation);
    // useEffect(() => {
    //     const userLocation: LocationInfo = {
    //         coordinate: {
    //             longitude: mapLocationCoordinate.longitude,
    //             latitude: mapLocationCoordinate.latitude,
    //         },
    //         accuracy: 10,
    //         heading: 0,
    //         speed: 0,
    //         unixTimestamp: Math.floor(new Date().getTime() / 1000),
    //     };
    //     dispatch(setLocation(userLocation));
    // }, [mapLocationCoordinate]);
    
    return <></>;
}

export default WatchUserLocation;