import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import {BrowserRouter} from "react-router-dom";
import {QueryParamProvider} from "use-query-params";
import {ReactRouter6Adapter} from "use-query-params/adapters/react-router-6";
import mapboxgl from "mapbox-gl";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX!;
(mapboxgl as any).workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const root = ReactDOM.createRoot(
        document.getElementById('root') as HTMLElement
);

root.render(
        <React.StrictMode>
            <BrowserRouter>
                <QueryParamProvider adapter={ReactRouter6Adapter}>
                    <App/>
                </QueryParamProvider>
            </BrowserRouter>
        </React.StrictMode>,
);
