import MapElementProps from "../MapElementProps";

import './MapStyleControl.scss';
import {useAppSelector} from "../../../redux/hooks";
import {selectLocationError} from "../../../redux/features/location/slice";
import MapIControl from "./MapIControl";

const LocationErrorControl = ({map}: MapElementProps) => {
    const locationError: string | undefined = useAppSelector(selectLocationError);

    return (
        <MapIControl map={map!} position='top-left' doShow={() => !!locationError}>
            <div className="error-control">
                Something is wrong with the location: {locationError}
            </div>
        </MapIControl>
    );
}

export default LocationErrorControl;