import './MapView.scss'
import React, {useCallback, useEffect, useState} from "react";
import Map from '../components/map/Map';
import UserLocationMarker from "../components/map/UserLocationMarker";
import GoToUserLocationControl from "../components/map/controls/GoToUserLocationControl";
import MapStyleControl from "../components/map/controls/MapStyleControl";
import AllowLocationAccessControl from "../components/map/controls/AllowLocationAccessControl";
import LocationErrorControl from "../components/map/controls/LocationErrorControl";
import WaitingForUserLocationControl from "../components/map/controls/WaitingForUserLocationControl";
import ColorPicker from "../components/map/controls/ColorPicker";
import TilesLayer from "../components/map/layers/TilesLayer";
import GridLayer from "../components/map/layers/GridLayer";
import DoNotPaint from "../components/map/controls/DoNotPaint";
import RoundedLocationsLayer from "../components/map/layers/RoundedLocationsLayer";
import UserLocationLayer from "../components/map/layers/UserLocationLayer";
import TestLayer from "../components/map/layers/TestLayer";

const MapView = () => {
    return (
        <Map>
            <GridLayer />
            {/*<TestLayer />*/}
            {/*<RoundedLocationsLayer />*/}
            {/*<UserLocationLayer />*/}
            <TilesLayer />
            <AllowLocationAccessControl />
            <LocationErrorControl />
            <WaitingForUserLocationControl />
            <UserLocationMarker/>
            <MapStyleControl />
            <GoToUserLocationControl />
            <ColorPicker />
            <DoNotPaint />
        </Map>
    );
}

export default MapView;