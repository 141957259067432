import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {createWhitelistFilter} from 'redux-persist-transform-filter';
import {RootState} from "../../store";

export interface LocationState {
    userLocation: LocationInfo | undefined,
    locationAccessAllowed: boolean | undefined;
    locationError: string | undefined;
    lowAccuracy: boolean;
}

const sliceName = 'location';

export const persistFilter = createWhitelistFilter(
    sliceName,
    ['userLocation', 'lowAccuracy']
);

const initialState: LocationState = {
    userLocation: undefined,
    locationAccessAllowed: undefined,
    locationError: undefined,
    lowAccuracy: true,
};

export const selectUserLocation = (state: RootState) => state.location.userLocation;
export const selectLocationAccessAllowed = (state: RootState) => state.location.locationAccessAllowed;
export const selectLocationError = (state: RootState) => state.location.locationError;

export interface LocationInfo {
    coordinate: LocationCoordinate,
    heading: number | null,
    speed: number | null,
    unixTimestamp: number,
    accuracy: number,
}

export interface LocationCoordinate {
    longitude: number,
    latitude: number,
}

export const locationSlice = createSlice({
    name: sliceName,
    initialState: initialState,
    reducers: {
        locationAccessNotAllowed(state) {
            state.locationAccessAllowed = false;
        },
        locationErrorHappened(state, action: PayloadAction<string>) {
            state.locationError = action.payload;
        },
        setLocation(state, action: PayloadAction<LocationInfo>) {
            state.locationError = undefined;
            state.locationAccessAllowed = true;
            state.lowAccuracy = action.payload.accuracy > 50; // accuracy in meters
            state.userLocation = action.payload;
        }
    },
});

export const {
    locationAccessNotAllowed,
    locationErrorHappened,
    setLocation,
} = locationSlice.actions;

export default locationSlice.reducer;
