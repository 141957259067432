import * as turf from "@turf/turf";
import {MapBounds} from "./slice";
import {LocationCoordinate} from "../location/slice";

export const coordStep = 0.000025;
export const nbDecimals = 6;
    
export const inflateBounds = (bounds: MapBounds, distanceInMeters: number): MapBounds => {
    const shiftedNe = turf.transformTranslate(
        turf.point([bounds.east, bounds.north]),
        distanceInMeters,
        45,
        {
            units: 'meters'
        }
    )
    const shiftedSw = turf.transformTranslate(
        turf.point([bounds.west, bounds.south]),
        distanceInMeters,
        -135,
        {
            units: 'meters'
        }
    )

    return {
        north: shiftedNe.geometry.coordinates[1],
        east: shiftedNe.geometry.coordinates[0],
        south: shiftedSw.geometry.coordinates[1],
        west: shiftedSw.geometry.coordinates[0],
    };
}

export const boundsContainsBounds = (bounds: MapBounds, otherBounds: MapBounds): boolean => {
    return bounds.north >= otherBounds.north &&
        bounds.south <= otherBounds.south &&
        bounds.east >= otherBounds.east &&
        bounds.west <= otherBounds.west;
}

export const roundBoundsCoordinates = (bounds: MapBounds): MapBounds => {
    return {
        north: roundNumber(bounds.north),
        south: roundNumber(bounds.south),
        east: roundNumber(bounds.east),
        west: roundNumber(bounds.west),
    };
}

const roundNumber = (n: number) => {
    return parseFloat((Math.round(n / coordStep) * coordStep).toFixed(nbDecimals));
}

export const roundCoordinate = (coordinate: LocationCoordinate): LocationCoordinate => {
    return {
        longitude: roundNumber(coordinate.longitude),
        latitude: roundNumber(coordinate.latitude),
    };
}

export const coordinateToBounds = (coordinate: LocationCoordinate) : MapBounds => {
    const roundedCoordinate = roundCoordinate(coordinate);
    return {
        north: roundedCoordinate.latitude + coordStep / 2,
        south: roundedCoordinate.latitude - coordStep / 2,
        east: roundedCoordinate.longitude + coordStep / 2,
        west: roundedCoordinate.longitude - coordStep / 2,
    };
}