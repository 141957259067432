import {combineReducers} from 'redux'
import locationReducer from './features/location/slice'
import mapReducer from './features/map/slice'
import colorReducer from './features/color/slice'
import firestoreReducer from './features/firestore/slice';
import tilesReducer from './features/firestore/tiles/slice'

const rootReducer = combineReducers({
    location: locationReducer,
    map: mapReducer,
    color: colorReducer,
    firestore: firestoreReducer,
    tiles: tilesReducer
});

export default rootReducer;