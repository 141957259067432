import Root from "./Root";
import Error from "./Error";
import {Route, Routes} from "react-router-dom";
import React, {useEffect} from "react";
import MapView from "./MapView";

const RootNavigation = () => {
    return (
            <Routes>
                <Route path="/"
                       element={<Root/>}
                       errorElement={<Error/>}
                >
                    <Route index element={<MapView />}/>
                </Route>
            </Routes>);
};

export default RootNavigation;