import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {createBlacklistFilter} from 'redux-persist-transform-filter';
import {RootState} from "../../store";
import {LocationCoordinate} from "../location/slice";

export interface MapState {
    locationAndZoom: MapLocationAndZoom,
    bounds: MapBounds,
    styleUrl: string,
}

export interface MapLocationAndZoom {
    location: LocationCoordinate,
    zoom: number,
}

export interface MapBounds {
    north: number,
    south: number,
    east: number,
    west: number;
}

const sliceName = 'map';

export const persistFilter = createBlacklistFilter(
    sliceName,
    []
);

const initialState: MapState = {
    locationAndZoom: {
        location: {
            longitude: 0,
            latitude: 0,
        },
        zoom: 2,
    },
    bounds: {
      north: 10,
      south: -10,
      east: 10,
      west: -10,  
    },
    styleUrl: 'mapbox://styles/mapbox/satellite-streets-v12',
};

export const selectMapState = (state: RootState) => state.map;
export const selectMapLocation = (state: RootState) => state.map.locationAndZoom.location;
export const selectMapZoom = (state: RootState) => state.map.locationAndZoom.zoom;
export const selectMapStyle = (state: RootState) => state.map.styleUrl;

export const mapSlice = createSlice({
    name: sliceName,
    initialState: initialState,
    reducers: {
        mapMoved(state, action: PayloadAction<MapLocationAndZoom & MapBounds>) {
            if (Math.abs( state.locationAndZoom.location.longitude - action.payload.location.longitude) > 1e-6 ||
                Math.abs(state.locationAndZoom.location.latitude - action.payload.location.latitude) > 1e-6 ||
                state.locationAndZoom.zoom !== action.payload.zoom      
            ) {
                state.locationAndZoom = action.payload;
                state.bounds = action.payload;
            }
        },
        styleChanged(state, action: PayloadAction<string>) {
            state.styleUrl = action.payload;
        }
    },
});

export const {
    mapMoved,
    styleChanged,
} = mapSlice.actions;

export default mapSlice.reducer;
