import MapElementProps from "../MapElementProps";
import {useEffect} from "react";
import './MapStyleControl.scss';
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {selectMapStyle, styleChanged} from "../../../redux/features/map/slice";
import MapIControl from "./MapIControl";

const MapStyleControl = ({map}: MapElementProps) => {
    const mapStyle = useAppSelector(selectMapStyle);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (map) {
            map.setStyle(mapStyle);
        }
    }, [map, mapStyle])

    const styles = [
        {name: 'streets', url: 'mapbox://styles/mapbox/streets-v12'},
        {name: 'outdoors', url: 'mapbox://styles/mapbox/outdoors-v12'},
        {name: 'light', url: 'mapbox://styles/mapbox/light-v11'},
        {name: 'dark', url: 'mapbox://styles/mapbox/dark-v11'},
        // {name: 'satellite', url: 'mapbox://styles/mapbox/satellite-v9'}, // not a globe
        {name: 'satellite-streets', url: 'mapbox://styles/mapbox/satellite-streets-v12'},
        // {name: 'day', url: 'mapbox://styles/mapbox/navigation-day-v1'},
        // {name: 'night', url: 'mapbox://styles/mapbox/navigation-night-v1'}, // not a globe
    ];

    return (
            <MapIControl map={map!} position='top-right' doShow={() => true}>
                {
                    styles.map(v =>
                            <div key={v.name}
                                 className={'style-div' + (mapStyle == v.url ? ' style-div-selected' : '')}>
                                <div>
                                    <button onClick={() => {
                                        map!.setStyle(v.url);
                                        map!.once('styledata', () => {
                                            dispatch(styleChanged(v.url));
                                        })
                                    }}>
                                        <img src={`/map/styles/${v.name}.png`} alt={v.name}/>
                                    </button>
                                </div>
                            </div>)
                }
            </MapIControl>
    );
}

export default MapStyleControl;