import {
    configureStore
} from '@reduxjs/toolkit';
import {
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
    StateReconciler,
    persistStore,
} from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import localStorage from "redux-persist/es/storage";

import rootReducer from "./reducers";
import {persistFilter as locationPersistFilter} from './features/location/slice';
import {persistFilter as mapPersistFilter} from './features/map/slice';
import {persistFilter as colorPersistFilter} from "./features/color/slice";
import {persistFilter as firestorePersistFilter} from "./features/firestore/slice";
import {persistFilter as tilesPersistFilter} from "./features/firestore/tiles/slice";

const persistConfig = {
    key: 'root',
    version: 1,
    storage: localStorage,
    stateReconciler: autoMergeLevel2 as StateReconciler<any>,
    transforms: [
        locationPersistFilter,
        mapPersistFilter,
        colorPersistFilter,
        firestorePersistFilter, 
        tilesPersistFilter,
    ]
};
const persistedReducer = persistReducer(
    persistConfig,
    rootReducer
);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
                warnAfter: 1000,
            },
            immutableCheck: {
                warnAfter: 1000,
            },
        }),
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
