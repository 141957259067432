import {LocationCoordinate, LocationInfo, selectUserLocation} from "../../redux/features/location/slice";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import MapElementProps from "./MapElementProps";
import {useEffect, useRef, useState} from "react";
import mapboxgl from "mapbox-gl";
import {selectColor, selectDoPaint} from "../../redux/features/color/slice";
import {colorToHex} from "../../redux/features/color/color";
import * as turf from "@turf/turf";
import {Feature, Point} from "@turf/helpers";
import {Properties} from "@turf/turf";
import {paintCoordinates} from "../../redux/features/firestore/tiles/slice";

let prevLocation: LocationCoordinate | undefined = undefined;

const UserLocationMarker = ({map}: MapElementProps) => {
    const userLocation: LocationInfo | undefined = useAppSelector(selectUserLocation);
    const [marker, setMarker] = useState<mapboxgl.Marker | undefined>();
    const doPaint: boolean | undefined = useAppSelector(selectDoPaint);
    const color = useAppSelector(selectColor);

    const ref = useRef<HTMLDivElement>(null);
   
    useEffect(() => {
        if(map && ref.current) {
            setMarker(new mapboxgl.Marker(ref.current!, {
                anchor: 'bottom-left',
                offset: [-2, 10]
            }));
        }
    }, [map, ref.current])

    const dispatch = useAppDispatch();
    useEffect(() => {
        if(marker && userLocation && userLocation.coordinate) {
            marker.setLngLat([userLocation.coordinate.longitude, userLocation.coordinate.latitude]);
            marker.addTo(map!);

            if(doPaint) {
                const paintTiles: LocationCoordinate[] = [];
                if (prevLocation) {
                    let distance = turf.distance(
                            [prevLocation.longitude, prevLocation.latitude],
                            [userLocation.coordinate.longitude, userLocation.coordinate.latitude],
                            {
                                units: 'meters'
                            });
                    if (distance > 1.5) {
                        if (distance > 50) {
                            // more than 50 meters in one seconds is almost 200kph, ignore it
                            return;
                        }
                        const bearing = turf.rhumbBearing(
                                [prevLocation.longitude, prevLocation.latitude],
                                [userLocation.coordinate.longitude, userLocation.coordinate.latitude])

                        while (distance > 1.5) {
                            const nextStep: Feature<Point, Properties> = turf.rhumbDestination(
                                    [prevLocation!.longitude, prevLocation!.latitude],
                                    1.5,
                                    bearing, {
                                        units: 'meters'
                                    })
                            prevLocation = {
                                longitude: nextStep.geometry.coordinates[0],
                                latitude: nextStep.geometry.coordinates[1]
                            }
                            distance = turf.distance(
                                    [prevLocation.longitude, prevLocation.latitude],
                                    [userLocation.coordinate.longitude, userLocation.coordinate.latitude],
                                    {
                                        units: 'meters'
                                    });
                            paintTiles.push(prevLocation);
                        }
                    }
                }

                prevLocation = userLocation.coordinate;
                paintTiles.push(prevLocation);
                dispatch(paintCoordinates({
                    coordinates: paintTiles,
                    color: color,
                }));
            } else {
                prevLocation = userLocation.coordinate;
            }
        }
    }, [userLocation, marker])

    if(!map) {
        return <></>;
    }

    return (<div ref={ref}>
        <svg fill={colorToHex(color)} stroke='#ffffff' width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <path d="M27.555 8.42c-1.355 1.647-5.070 6.195-8.021 9.81l-3.747-3.804c3.389-3.016 7.584-6.744 9.1-8.079 2.697-2.377 5.062-3.791 5.576-3.213 0.322 0.32-0.533 2.396-2.908 5.286zM18.879 19.030c-1.143 1.399-2.127 2.604-2.729 3.343l-4.436-4.323c0.719-0.64 1.916-1.705 3.304-2.939l3.861 3.919zM15.489 23.183v-0.012c-2.575 9.88-14.018 4.2-14.018 4.2s4.801 0.605 4.801-3.873c0-4.341 4.412-4.733 4.683-4.753l4.543 4.427c0 0.001-0.009 0.011-0.009 0.011z"></path>
        </svg>
    </div>);
}

export default UserLocationMarker;