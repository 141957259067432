import MapElementProps from "../MapElementProps";
import {LocationInfo, selectUserLocation} from "../../../redux/features/location/slice";
import {useAppSelector} from "../../../redux/hooks";
import {useEffect, useState} from "react";
import MapIControl from "./MapIControl";

const GoToUserLocationControl = ({map}: MapElementProps) => {
    const userLocation: LocationInfo | undefined = useAppSelector(selectUserLocation);
    const [followingUserLocation, setFollowingUserLocation] = useState<boolean>(
        localStorage.getItem('followingUserLocation') !== 'false');

    useEffect(() => {
        if(map) {
            if(followingUserLocation && userLocation && userLocation.coordinate) {
                map.panTo([userLocation.coordinate.longitude, userLocation.coordinate.latitude]);
            }
        }
        localStorage.setItem('followingUserLocation', followingUserLocation ? 'true' : 'false')
    }, [map, followingUserLocation, userLocation])

    return (
        <MapIControl map={map!} position='top-right' doShow={() => true}>
            <button className="mapboxgl-ctrl-geolocate" style={{
                width: '39px', height: '39px',
                backgroundColor: followingUserLocation ? 'yellow' : ''
            }}
                    type="button" aria-label="Find my location" onClick={
                () => {
                    if (userLocation && map) {
                        setFollowingUserLocation(current => !current);
                    }
                }
            }>
                <span className="mapboxgl-ctrl-icon" aria-hidden="true" title="Find my location"/>
            </button>
        </MapIControl>
    );
}

export default GoToUserLocationControl;