import './ColorPicker.scss'
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import MapIControl from './MapIControl';
import MapElementProps from "../MapElementProps";
import {doNotPaint, selectDoPaint} from "../../../redux/features/color/slice";
import './DoNotPaint.scss';

const DoNotPaint = ({map}: MapElementProps) => {
    const dispatch = useAppDispatch();
    const doPaint: boolean | undefined = useAppSelector(selectDoPaint);

    return (
            <MapIControl map={map!} position='bottom-left' doShow={() => true}>
                <div className={'do-not-paint-container' + (doPaint ? '' : ' not-painting')}>
                    <button className='do-not-paint-button' onClick={() => {
                        dispatch(doNotPaint());
                    }}>
                        <svg fill='#fff' stroke='#000' width="42px" height="42px" viewBox="0 0 32 32"
                             version="1.1" xmlns="http://www.w3.org/2000/svg">
                            <path d="M27.555 8.42c-1.355 1.647-5.070 6.195-8.021 9.81l-3.747-3.804c3.389-3.016 7.584-6.744 9.1-8.079 2.697-2.377 5.062-3.791 5.576-3.213 0.322 0.32-0.533 2.396-2.908 5.286zM18.879 19.030c-1.143 1.399-2.127 2.604-2.729 3.343l-4.436-4.323c0.719-0.64 1.916-1.705 3.304-2.939l3.861 3.919zM15.489 23.183v-0.012c-2.575 9.88-14.018 4.2-14.018 4.2s4.801 0.605 4.801-3.873c0-4.341 4.412-4.733 4.683-4.753l4.543 4.427c0 0.001-0.009 0.011-0.009 0.011z"/>
                            {!doPaint &&
                                    <g transform="matrix(0.589271, 1.063072, -1.063072, 0.589271, 26.46858, -11.125622)">
                                        <path d="M 23.341 11.864 L 9.341 25.864 M 9.341 11.864 L 23.341 25.864"
                                              strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M 23.341 11.864 L 9.341 25.864 M 9.341 11.864 L 23.341 25.864"
                                              stroke="#000000"
                                              strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    </g>
                            }
                        </svg>
                    </button>
                </div>
            </MapIControl>
    );
}

export default DoNotPaint;