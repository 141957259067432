import { initializeApp } from "firebase/app";

const firebaseBackend = initializeApp({
    apiKey: "AIzaSyAxRdSYm1W6mQ3d39npaRiBmH6Y3PrgtHQ",
    authDomain: "paint-the-world-7a6b2.firebaseapp.com",
    projectId: "paint-the-world-7a6b2",
    storageBucket: "paint-the-world-7a6b2.appspot.com",
    messagingSenderId: "1084639947763",
    appId: "1:1084639947763:web:83af55019a8f8ce6c38086",
    measurementId: "G-K33353P81D"
});
export default firebaseBackend;
