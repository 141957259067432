export interface Color {
    r: number,
    g: number,
    b: number,
}

const componentToHex = (c: number) => {
    var hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
}
export const colorToHex = (color: Color) => {
    return "#" + componentToHex(color.r) + componentToHex(color.g) + componentToHex(color.b);
}
