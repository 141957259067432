import MapElementProps from "../MapElementProps";
import {useAppSelector} from "../../../redux/hooks";
import {useEffect, useState} from "react";
import {GeoJSONSource} from "mapbox-gl";
import {selectMapStyle} from "../../../redux/features/map/slice";
import useInterval from "../../../helpers/useInterval";

export interface MapLayerProps extends MapElementProps {
    data: GeoJSON.Feature<GeoJSON.Geometry> | GeoJSON.FeatureCollection<GeoJSON.Geometry> | undefined;
    layer: mapboxgl.AnyLayer;
}

const GeoJsonLayer = ({map, data, layer}: MapLayerProps) => {
    const [dirty, setDirty] = useState(true);

    useInterval(() => {
        if (dirty) {
            setDirty(false);
            do_draw_layer();
        }
    }, 100);
    
    const do_draw_layer = () => {
        if (!map || !map.loaded()) {
            setDirty(true);
            return;
        }


        const source_name = `${layer.id}_source`;
        (layer as any).source = source_name;

        // console.log('do draw', ((data as GeoJSON.Feature<GeoJSON.Geometry>).geometry as GeoJSON.MultiLineString).coordinates.length);
        if (data) {
            // switch (data.type) {
            //     case "Feature":
            //         console.log(layer.id, 'Feature', data.geometry);
            //         break;
            //     case "FeatureCollection":
            //         console.log(layer.id, 'FeatureCollection', data.features);
            //         break;
            // }
            const source: GeoJSONSource = map.getSource(source_name) as GeoJSONSource;
            if (source === undefined) {
                map.addSource(source_name, {
                    type: 'geojson',
                    data: data
                });

                map.addLayer(layer);
            }
            else {
                source.setData(data);
            }
        } else {
            console.log(layer.id, 'no data', data);
        }
    };

    useEffect(() => setDirty(true), [])
    const mapStyle = useAppSelector(selectMapStyle);
    useEffect(() => setDirty(true), [mapStyle])
    useEffect(() => setDirty(true), [data])

    return (<></>);
}

export default GeoJsonLayer;