import React, {useEffect, useRef, useState} from "react";
import mapboxgl from "mapbox-gl";
import MapElementProps from "./MapElementProps";
import './Map.scss';
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {mapMoved, MapState, selectMapState} from "../../redux/features/map/slice";

type MapProps = {
    children: JSX.Element | JSX.Element[]
}

const Map = ({children}: MapProps) => {
    const mapContainer = useRef<HTMLDivElement>(null);
    const mapRef = useRef<mapboxgl.Map | null>(null);
    const [map, setMap] = useState<mapboxgl.Map | null>();

    const mapState: MapState = useAppSelector(selectMapState);

    useEffect(() => {
        if (!mapContainer.current || !mapState)
            return;
        if (mapRef.current)
            return; // initialize map only once

        mapRef.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/mapbox/satellite-streets-v12',
            center: [mapState.locationAndZoom.location.longitude, mapState.locationAndZoom.location.latitude],
            zoom: mapState.locationAndZoom.zoom
        });
        setMap(mapRef.current);
    }, [mapContainer.current]);

    const [childrenWithMap, setChildrenWithMap] = useState<JSX.Element[]>([]);

    const dispatch = useAppDispatch();
    const refreshStore = () => {
        const center = mapRef.current!.getCenter();
        const bounds = mapRef.current!.getBounds();
        dispatch(mapMoved({
            location: {
                longitude: center.lng,
                latitude: center.lat,
            },
            zoom: mapRef.current!.getZoom(),
            north: bounds.getNorth(),
            south: bounds.getSouth(),
            east: bounds.getEast(),
            west: bounds.getWest(),
        }));
    };

    useEffect(() => {
        if (map) {
            map.on('move', refreshStore);

            if (!Array.isArray(children)) {
                children = [children];
            }
            setChildrenWithMap(React.Children.map(children, child => {
                if (React.isValidElement(child)) {
                    const mapElementChild = child as React.ReactElement<MapElementProps>;
                    if (mapElementChild) {
                        return React.cloneElement(mapElementChild, {
                            map: map,
                        });
                    }
                }
                return child;
            }))
        }
    }, [map])

    return (
            <div>
                <div ref={mapContainer} className="map-container"/>
                <div>
                    {childrenWithMap}
                </div>
            </div>
    );
}

export default Map;