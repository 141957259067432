import React from 'react';
import {Provider} from "react-redux";
import {persistor, store} from "./redux/store";
import {PersistGate} from "redux-persist/integration/react";
import RootNavigation from "./pages/RootNavigation";
import WatchUserLocation from "./redux/features/location/WatchUserLocation";

function App() {
    return (
            <Provider store={store}>
                <PersistGate persistor={persistor}>
                    <RootNavigation/>
                    <WatchUserLocation/>
                </PersistGate>
            </Provider>
    );
}

export default App;
